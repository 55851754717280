import React from 'react'


function NotFound() {
    return (
        <div style={{textAlign: 'center', height: '100vh', background: 'blue'}}>
            <h1 style={{color: 'white', padding: '50px'}}>Sorry Page Not Found </h1>
        </div>
    );
}

export default NotFound;